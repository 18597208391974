import { Component } from '@angular/core';
import langJson from '../../../assets/json/lang.json';
import { Router } from '@angular/router';
import { NavigatorService } from '../../service/navigator/navigator.service';
import { Race } from '../../interface/race/race';

@Component({
  selector: 'app-schedule',
  standalone: true,
  imports: [],
  templateUrl: './schedule.component.html',
  styleUrl: './schedule.component.less',
})
export class ScheduleComponent {
  raceList!: Race[];
  lang: string = '';
  langData: any = {};

  constructor(private router: Router, private navigator: NavigatorService) {
    this.langData = langJson;
    this.lang = this.navigator.getLang();
  }

  ngOnInit(): void {
    this.loadRaceListFromJson()
      .then((raceList: any) => {
        this.raceList = raceList;
      })
      .catch((error: any) => {
        console.error('Erruer :', error);
      });
  }

  goToPrediction() {
    this.router.navigate(['/prediction/']);
  }

  //fonction à appeler pour lire les courses sur le fichier json
  loadRaceListFromJson = () => {
    return new Promise((resolve, reject) => {
      this.raceList = [];
      fetch('assets/json/schedule.json')
        .then((response) => response.json())
        .then((json) => {
          json.forEach((race: Race) => {
            let newRace = {
              label: race.label,
              code: race.code,
              startTime: race.startTime,
              predictionSelected: race.predictionSelected,
            };
            this.raceList.push(newRace);
          });
          resolve(this.raceList);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
