import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { md5 } from 'js-md5';
import { AdminPlsComponent } from '../admin-pls/admin-pls.component';

@Component({
  selector: 'app-auth',
  standalone: true,
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.less',
  imports: [ReactiveFormsModule, AdminPlsComponent],
})
export class AuthComponent {
  auth: boolean = true;
  errorMessage = '';

  authForm = this.formBuilder.group({
    //1st step => FormGroup{id:FormControl('')}
    code: ['', Validators.required],
    key: ['', Validators.required],
  });

  constructor(private formBuilder: FormBuilder) {
    // console.log(
    //   '',
    //   new Date(Date.now()).getMonth() + 1 + new Date(Date.now()).getDate()
    // );
    //console.log('', new Date(Date.now()).getDate());
  }

  authFormSubmit() {
    if (this.authForm.valid) {
      this.checkAuthKey(
        this.authForm.get('code')?.value,
        this.authForm.get('key')?.value
      );
      //this;
      //this.code=this.authForm.get('code')?.value;
      //this.key=this.authForm.get('key')?.value;
    }
  }

  checkAuthKey(code: any, key: any) {
    console.log(key);
    console.log(
      new Date(Date.now()).getMonth() + 1 + new Date(Date.now()).getDate()
    );

    if (
      key ===
      (
        new Date(Date.now()).getMonth() +
        1 +
        new Date(Date.now()).getDate()
      ).toString()
    ) {
      if (md5(code) === 'a281be96c665f10849a1db768c8b8a00') {
        this.errorMessage = '';
        this.auth = true;
      } else {
        this.errorMessage = 'Wrong code';
        this.auth = false;
      }
    } else {
      this.errorMessage = 'Wrong key';
      this.auth = false;
    }
  }
}
