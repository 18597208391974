import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../interface/user/user';
import { Athlete } from '../../interface/athlete/athlete';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  url_gfunctions: string;
  /*httpOptions = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Max-Age': '3600',
  });*/

  constructor(private http: HttpClient) {
    this.url_gfunctions =
      'https://europe-west9-ltif-predictions.cloudfunctions.net/';
    //this.init();
  }

  init() {
    console.log('init');

    /*this.http
      .get<Object[]>(
        'https://europe-west9-ltif-predictions.cloudfunctions.net/function-1'
      )
      .subscribe((res) => {
        console.log('===>', res);
      });*/
  }

  /***** PREDICTIONS *****/

  addUser(myUser: User): Observable<Object[]> {
    return this.http.post<any>(this.url_gfunctions + 'add-user', {
      ...myUser,
      //headers: this.httpOptions,
    });
  }

  addPrediction(
    userId: string,
    serie: string | null,
    predictions: any
  ): Observable<Object[]> {
    const data = {
      userId: userId,
      serie: serie,
      predictions: predictions,
    };

    return this.http.post<any>(this.url_gfunctions + 'add-prediction', {
      ...data,
    });
  }
  /***** end_ADMIN-PLS *****/

  /***** ADMIN-PLS *****/
  //Calcul stats
  getStats(): Observable<Object[]> {
    return this.http.post<any>(this.url_gfunctions + 'get-stat', {});
  }

  //Push startlist of serie
  setStartlist(serie: string, startlist: Athlete[]): Observable<Object[]> {
    console.log('Set Startlist fo firestore');
    console.log('Serie:', serie);
    console.log(startlist);

    return this.http.post<any>(this.url_gfunctions + 'set-startlist', {
      serie: serie,
      startlist: startlist,
    });
  }

  getStartlist(serie: string): Observable<Object[]> {
    return this.http.post<any>(this.url_gfunctions + 'get-startlist', {
      serie: serie
    });
    /*return this.http.get<Object[]>(
      'https://europe-west9-ltif-predictions.cloudfunctions.net/get-startlist'
    );*/
  }

  //Get users predictions of serie
  getPredictionsSeries(serie: string): Observable<Object[]> {
    return this.http.post<any>(this.url_gfunctions + 'get-prediction', {
      serie: serie,
    });
  }

  setPredictionsPoints(xx: any): Observable<Object[]> {
    return this.http.post<any>(this.url_gfunctions + 'XXXXX', {
      xx: xx,
    });
  }

  //Set point of serie for userId
  async setUserPoints(userId: string, /*serie: string,*/ data: any) {
    //Push startlist of serie
    return this.http.post<any>(this.url_gfunctions + 'set-user-point', {
      userId: userId,
      data: data,
    });
  }
  /***** end_ADMIN-PLS *****/

  /*//Add new document in Collection
  async addDocument(collectionName: string, data: any) {
    try {
      const docRef = await addDoc(collection(this.db, collectionName), data);
      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }

  //Read Documents of Collection
  async getDocuments(collectionName: string) {
    try {
      const querySnapshot = await getDocs(collection(this.db, collectionName));
      querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);
        console.log(doc.data());
      });
    } catch (e) {
      console.error('Error reading documents: ', e);
    }
  }*/
}
