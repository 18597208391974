import { Component } from '@angular/core';
import { UserDataComponent } from '../user-data/user-data.component';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NavigatorService } from '../../service/navigator/navigator.service';
import { PredictionService } from '../../service/prediction/prediction.service';
import langJson from '../../../assets/json/lang.json';

@Component({
  selector: 'app-prediction',
  standalone: true,
  templateUrl: './prediction.component.html',
  styleUrl: './prediction.component.less',
  imports: [UserDataComponent, RouterLink, RouterLinkActive],
})
export class PredictionComponent {
  lang: string = '';
  langData: any = {};

  serieEWQ: boolean = false;
  serieEWSF: boolean = false;
  serieEWF: boolean = false;
  serieEMQ: boolean = false;
  serieEMSF: boolean = false;
  serieEMF: boolean = false;

  constructor(
    private navitator: NavigatorService,
    private pred: PredictionService,
    private router: Router
  ) {
    this.langData = langJson;
    this.lang = this.navitator.getLang();

    this.checkAccess();
  }

  ngOnInit(): void {
    this.checkPredictionsSerieArchive();
  }

  private checkAccess() {
    if (this.navitator.checkLogOK() === false) {
      console.log("User don't have access to this content, please login");
      this.router.navigate(['/login']);
    }
  }

  checkPredictionsSerieArchive() {
    if (Object.keys(this.pred.getPredictionSerie('EW-Q')).length > 0) {
      this.serieEWQ = true;
    }
    if (Object.keys(this.pred.getPredictionSerie('EW-SF')).length > 0) {
      this.serieEWSF = true;
    }
    if (Object.keys(this.pred.getPredictionSerie('EW-F')).length > 0) {
      this.serieEWF = true;
    }
    if (Object.keys(this.pred.getPredictionSerie('EM-Q')).length > 0) {
      this.serieEMQ = true;
    }
    if (Object.keys(this.pred.getPredictionSerie('EM-SF')).length > 0) {
      this.serieEMSF = true;
    }
    if (Object.keys(this.pred.getPredictionSerie('EM-F')).length > 0) {
      this.serieEMF = true;
    }
  }
}
