import { Injectable } from '@angular/core';
import { User } from '../../interface/user/user';
import langDataJsonFile from '../../../assets/json/lang.json';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {
  myUser: User = {
    id: '',
    lastname: '',
    firstname: '',
    rules: false,
  }; //Default User;
  lang: string = 'en';
  langData: any[] = [];

  constructor() {}

  initService() {
    this.storagePush('param', { version: '1.0' });
    this.initUser();
    this.getLang();
  }

  initUser() {
    const user = this.storageGet('User'); //Get localStorage

    //If don't exist, create default User
    if (!user) {
      //console.log('create empty user in localStorage');
      this.storagePush('User', this.myUser);
    } else {
      if (Object.keys(user).length === 0) {
        console.log('create empty user in localStorage');
        this.storagePush('User', this.myUser);
      } else {
        //init with data of localstrage
        console.log('Load localStorage user');
        this.myUser = user;
      }
    }

    /*if (Object.keys(user).length) {
      console.log('not user');

      this.storagePush('User', this.myUser);
    } else {
      //init with data of localstrage
      this.myUser = user;
    }*/
  }

  //Get data of User
  getUser() {
    return this.storageGet('User');
  }

  //Push data of User
  setUser(user: User) {
    this.storagePush('User', user);
  }

  //Check if user is connected
  getUserId() {
    return this.myUser.id;
  }

  checkLogOK() {
    if (this.myUser.id !== '') {
      return true;
    } else {
      return false;
    }
  }

  changeLang(lang: string) {
    this.storagePush('lang', lang);
    this.getLang();
  }

  // getLang() {
  //   var lang: any = this.storageGet('lang');
  //   if(!lang){
  //     this.changeLang('en');
  //   }
  //   else if (Object.keys(lang).length === 0) {
  //     this.changeLang(this.lang);
  //   }
  //   else{
  //     console.log('load lang localStorage');
  //     this.lang = lang;
  //   }
  // }
  
  
  getLang() {
    var lang: any = this.storageGet('lang');
    console.log(localStorage);
    //If unknown, default language
    if (!lang) {
      //console.log('Define default language in localStorage');
      this.changeLang(this.lang);
    } else {
      if (Object.keys(lang).length === 0) {
        //console.log('Define default language in localStorage');
        this.changeLang(this.lang);
      } else {
        console.log('load lang localStorage');
        this.lang = lang;
      }
    }

    /*if (Object.keys(lang).length === 0) {
      this.changeLang(this.lang);
    } else {
      this.lang = lang;
    }*/

    this.getLangData();

    return this.lang;
  }

  //Read LangFile
  getLangData() {
    console.log('data', langDataJsonFile);
  }

  //Test phase => delete data
  DeleteDataUser() {
    //Only user => for obtaine Login page
    localStorage.removeItem('User');
    this.myUser.id = '';
  }
  DeleteDataAll() {
    //All data
    localStorage.removeItem('save_EW-Q');
    localStorage.removeItem('save_EM-Q');
    localStorage.removeItem('pred_EW-Q1');
    localStorage.removeItem('pred_EW-Q2');
    localStorage.removeItem('pred_EW-Q3');
    localStorage.removeItem('pred_EW-Q4');
    localStorage.removeItem('pred_EM-Q1');
    localStorage.removeItem('pred_EM-Q2');
    localStorage.removeItem('pred_EM-Q3');
    localStorage.removeItem('pred_EM-Q4');
    localStorage.removeItem('pred_EM-Q5');
    localStorage.removeItem('pred_EM-Q6');

    localStorage.removeItem('param');
    localStorage.removeItem('lang');

    this.DeleteDataUser();
    //localStorage.removeItem("Other"); // list of all key
  }

  private storagePush(key: string, data: any) {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(data));
      this.initUser();
    } else {
      //console.log("Navigator don't full open, Push localStorage error");
      //console.log(key, data);
    }
  }
  private storageGet(key: string) {
    if (typeof window !== 'undefined') {
      return JSON.parse(localStorage.getItem(key) || '{}');
    } else {
      //console.log("Navigator don't full open, Get localStorage error");
      //console.log(key);
    }
  }
}
