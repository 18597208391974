<div class="contentMain">
  <h1>{{ langData.loginPageTitle[lang] }}:</h1>

  <form [formGroup]="accessForm" (ngSubmit)="accessFormSubmit()">
    <p>
      @if(!accessForm.get('lastname')?.valid &&
      (accessForm.get('lastname')?.touched || !isFirstTry)){<span
        class="error"
        >{{ langData.lastnameError[lang] }}</span
      >}

      <input
        type="text"
        placeholder="{{ langData.lastname[lang] }}"
        formControlName="lastname"
        #lastNameInput
        (input)="lastNameInput.value = lastNameInput.value.toUpperCase()"
      />
    </p>
    <p>
      @if(!accessForm.get('firstname')?.valid &&
      (accessForm.get('firstname')?.touched || !isFirstTry)){<span
        class="error"
        >{{ langData.firstnameError[lang] }}</span
      >}
      <input
        type="text"
        placeholder="{{ langData.firstname[lang] }}"
        formControlName="firstname"
      />
    </p>
    <div>
      @if(!dateValidator(dayInput.value, monthInput.value, yearInput.value) &&
      !isFirstTry){
      <span class="error">{{ langData.birthDateError[lang] }}</span>
      }
      <input
        type="number"
        placeholder="{{ langData.formAccess_day[lang] }}"
        formControlName="birthDate_day"
        class="formBirthDate"
        #dayInput
        id="day"
        maxlength="2"
        id="day"
        (input)="this.moveFocusToMonth()"
      />
      <input
        type="number"
        placeholder="{{ langData.formAccess_month[lang] }}"
        formControlName="birthDate_month"
        class="formBirthDate"
        #monthInput
        id="month"
        maxlength="2"
        id="month"
        (input)="this.moveFocusToYear()"
      />
      <input
        type="number"
        placeholder="{{ langData.formAccess_year[lang] }}"
        formControlName="birthDate_year"
        class="formBirthDate"
        #yearInput
        id="year"
        maxlength="4"
        id="year"
        (input)="this.blurYear()"
        (blur)="this.formatYear(yearInput)"
      />
      <!-- <span *ngIf="accessForm.get('day')?.hasError('invalidDate') || accessForm.get('month')?.hasError('invalidDate') || accessForm.get('year')?.hasError('invalidDate')" class="error">{{ langData.birthDateError[lang] }}</span> -->
      <!-- @if(!accessForm.get('date')?.valid &&
      (accessForm.get('date')?.touched || !isFirstTry)){
        <span class="error">{{ langData.birthDateError[lang]}}</span>
      } -->
      <!-- <input type="text" onblur="(this.type='text')" onfocus="(this.type='date')" class="inputDate" formControlName="date" placeholder="{{ langData.birthDateFormat[lang]}}" #dateInput> -->
      <!-- <app-datepicker></app-datepicker> -->
    </div>
    <p>
      @if(!accessForm.get('email')?.valid && (accessForm.get('email')?.touched
      || !isFirstTry)){<span class="error">{{ langData.emailError[lang] }}</span
      >}
      <input
        type="email"
        placeholder="{{ langData.email[lang] }}"
        formControlName="email"
      />
    </p>
    <p>
      @if(!accessForm.get('rules')?.value && (accessForm.get('rules')?.touched
      || !isFirstTry)){<span class="error">{{ langData.rulesError[lang] }}</span
      ><br />}
      <input type="checkbox" id="checkBoxRules" formControlName="rules" /><label
        for="checkBoxRules"
        >{{ langData.rules[lang]
        }}<a
          href="https://drive.google.com/file/d/1TD5i4qSdBgeJlD_aGaLK1yV5rCNpJCHY/view?usp=sharing"
          target="_blank"
          >({{ langData.rulesPDF[lang] }})</a
        ></label
      >
    </p>
    <p>
      @if(isError){
      <strong class="error">{{ langData.registerError[lang] }}</strong>
      }
      <!--[disabled]="!accessForm.valid"-->
      <input type="submit" value="{{ langData.loginSubmit[lang] }}" />
    </p>
  </form>
  @if(isLoading){<app-loader></app-loader>}
</div>
