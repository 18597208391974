import { Component } from '@angular/core';
import { User } from '../../interface/user/user';
import { NavigatorService } from '../../service/navigator/navigator.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-data',
  standalone: true,
  imports: [],
  templateUrl: './user-data.component.html',
  styleUrl: './user-data.component.less',
})
export class UserDataComponent {
  user: User = { lastname: '', firstname: '', id: '', rules: false };

  constructor(private navigator: NavigatorService, private router: Router) {}

  ngOnInit(): void {
    this.getUserId();
  }

  getUserId() {
    this.user = this.navigator.getUser();
    this.user.id = this.navigator.getUserId();
  }

  logout() {
    this.navigator.DeleteDataAll();
    this.router.navigate(['/login']);
  }
}
