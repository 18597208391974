import { Component } from '@angular/core';
import langJson from '../../../assets/json/lang.json';
import { Athlete } from '../../interface/athlete/athlete';
import { PredictionService } from '../../service/prediction/prediction.service';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { NavigatorService } from '../../service/navigator/navigator.service';
import { FirestoreService } from '../../service/firestore/firestore.service';
import { LoaderComponent } from '../../interface/loader/loader.component';

@Component({
  selector: 'app-list-serie',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, LoaderComponent],
  templateUrl: './list-serie.component.html',
  styleUrl: './list-serie.component.less',
})
export class ListSerieComponent {
  lang: string = '';
  langData: any = {};

  serie: string | null = '';
  serieLabel: string = '';
  pageCurrent: number = 0;
  raceCurrent: string = '';
  raceList: string[] = [];
  athleteList: Athlete[] = [];
  athleteSelected: number = 0;
  descriptionLabel: string = '';
  serieCheck: boolean = false;
  athleteSummary: any[] = [];

  isLoading: boolean = false;

  constructor(
    private pred: PredictionService,
    private route: ActivatedRoute,
    private router: Router,
    private navigator: NavigatorService,
    private firestoreService: FirestoreService
  ) {
    this.langData = langJson;
    this.lang = this.navigator.getLang();

    this.serie = route.snapshot.paramMap.get('serie'); //Get URL serie id

    this.getSerieLabel();
    this.raceList = pred.getRaceList(this.serie);

    //Check if already -> predictions
    if (Object.keys(this.pred.getPredictionSerie(this.serie)).length > 0) {
      this.serieCheck = true;
      this.athleteList = [];

      this.raceList.forEach((race) => {
        let athletes: Athlete[] = this.pred.getPredictionRace(race);
        console.log(athletes);
        console.log(race);

        athletes.forEach((athlete) => {
          if (athlete.isSelected === 1) {
            this.athleteSummary.push({
              race: race,
              medal: 'gold',
              athlete: athlete,
            });
          }
          if (athlete.isSelected === 2) {
            this.athleteSummary.push({
              race: race,
              medal: 'silver',
              athlete: athlete,
            });
          }
          if (athlete.isSelected === 3) {
            this.athleteSummary.push({
              race: race,
              medal: 'bronze',
              athlete: athlete,
            });
          }
        });
        //this.athleteSummary.push({"race":race, "mdeal":"gold", "athlete"});
        this.athleteList.concat(this.pred.getPredictionRace(race));
      });

      this.athleteSummary.sort((a: any, b: any) => {
        if (a.athlete.isSelected < b.athlete.isSelected) {
          return -1;
        } else {
          return 1;
        }
      });

      this.athleteSelected = -100;
    }

    this.getDescriptionLabel();
  }

  ngOnInit(): void {
    this.navigator.initService();
    if (!this.serieCheck) {
      this.nextPage();
    }
  }

  //Get athleteList
  nextPage() {
    //save previous race
    if (this.pageCurrent > 0) {
      this.pred.savePredictionRace(this.raceCurrent, this.athleteList);
    }

    this.pageCurrent++; //add page

    if (this.pageCurrent > this.raceList.length) {
      this.save();
      return;
    }

    this.athleteSelected = 0; //reset -> select 1st

    this.athleteList = [];
    this.athleteList = this.pred.getAthleteList(
      this.raceList[this.pageCurrent - 1]
    );
    this.raceCurrent = this.athleteList[0].race;
    console.log(
      'Next page => Current page =>',
      this.raceList[this.pageCurrent - 1]
    );
  }

  //SAVE in DB
  save() {
    this.isLoading = true;
    var predictionsData: any = [];
    this.raceList.forEach((race) => {
      let raceData = this.pred.getPredictionRace(race);
      raceData.forEach((raceD: any) => {
        if (raceD.isSelected) {
          //Save only if in predictions and only data necessary
          predictionsData.push({
            race: raceD.race,
            bib: raceD.bib,
            isSelected: raceD.isSelected,
          });
        }
      });

      //predictionsData = predictionsData.concat(raceData);
    });
    this.firestoreService
      .addPrediction(this.navigator.getUserId(), this.serie, predictionsData)
      .subscribe((resPOST: any) => {
        console.log(
          'Prediction saved to Firestore by GFunctions, docId: ' + resPOST.docId
        );

        this.isLoading = false;

        this.pred.savePredictionSerie(this.serie);
        this.router.navigate(['/prediction']);
      });
  }

  //Click on athlete, add emoji
  clickAthlete(bib: string) {
    this.athleteList.forEach((athlete) => {
      if (
        athlete.bib === bib &&
        this.athleteSelected <= 2 &&
        (athlete.isSelected === undefined || athlete.isSelected === 0)
      ) {
        this.athleteSelected++;
        this.getDescriptionLabel();
        athlete.isSelected = this.athleteSelected;
      }
    });
  }

  //Rest Gold, Silver, Bronze of race
  resetChoice(race: string) {
    this.athleteList.forEach((athlete) => {
      athlete.isSelected = 0;
    });

    this.athleteSelected = 0;
    this.getDescriptionLabel();
  }

  //Write label of serie Code
  getSerieLabel() {
    switch (this.serie) {
      case 'EW-Q':
        this.serieLabel =
          this.langData.eliteWomen[this.lang] +
          ' - ' +
          this.langData.qualifier[this.lang];
        break;
      case 'EW-SF':
        this.serieLabel =
          this.langData.eliteWomen[this.lang] +
          ' - ' +
          this.langData.semifinal[this.lang];
        break;
      case 'EW-F':
        this.serieLabel =
          this.langData.eliteWomen[this.lang] +
          ' - ' +
          this.langData.finalA[this.lang];
        break;
      case 'EM-Q':
        this.serieLabel =
          this.langData.eliteMen[this.lang] +
          ' - ' +
          this.langData.qualifier[this.lang];
        break;
      case 'EM-SF':
        this.serieLabel =
          this.langData.eliteMen[this.lang] +
          ' - ' +
          this.langData.semifinal[this.lang];
        break;
      case 'EM-F':
        this.serieLabel =
          this.langData.eliteMen[this.lang] +
          ' - ' +
          this.langData.finalA[this.lang];
        break;
      default:
        this.serieLabel = '';
        break;
    }
  }

  getDescriptionLabel() {
    if (this.athleteSelected === -100) {
      this.descriptionLabel =
        this.langData.predictionChoiceAlready[this.lang] + ': ' + this.serie;
    } else if (this.athleteSelected === 0) {
      this.descriptionLabel = this.langData.clickOn1st[this.lang];
    } else if (this.athleteSelected === 1) {
      this.descriptionLabel = this.langData.clickOn2nd[this.lang];
    } else if (this.athleteSelected === 2) {
      this.descriptionLabel = this.langData.clickOn3rd[this.lang];
    } else {
      this.descriptionLabel = this.langData.clickOnStop[this.lang];
    }
  }
}
