<app-user-data></app-user-data>

<div class="contentMain center">
  <h1>{{ langData.menuPrediction[lang] }}</h1>

  <div class="predictionSerie">
    <p>🏊‍♀️🚴‍♀️🏃‍♀️ {{ langData.eliteWomen[lang] }} 🏊‍♀️🚴‍♀️🏃‍♀️</p>

    <a
      class="btn"
      routerLink="/prediction/serie/EW-Q"
      routerLinkActive="active"
      [class]="{ btnPredictionOK: serieEWQ }"
      >{{ langData.qualifier[lang] }}</a
    >
    <p class="predictionSerieNoStartlist">
      @if(!serieEWQ){
      <span>{{ langData.predictionOpened[lang] }}</span>
      }@if(serieEWQ){
      <span>{{ langData.predictionSaved[lang] }}</span>
      }
    </p>

    <a class="btn locked">{{ langData.semifinal[lang] }}</a>
    <p class="predictionSerieNoStartlist">
      {{ langData.waitStartlist[lang] }}
    </p>

    <a class="btn locked">{{ langData.finalA[lang] }}</a>
    <p class="predictionSerieNoStartlist">
      {{ langData.waitStartlist[lang] }}
    </p>

    <p>🏊🚴‍♂️🏃‍♂️ {{ langData.eliteMen[lang] }} 🏊🚴‍♂️🏃‍♂️</p>

    <a
      class="btn"
      routerLink="/prediction/serie/EM-Q"
      routerLinkActive="active"
      [class]="{ btnPredictionOK: serieEMQ }"
      >{{ langData.qualifier[lang] }}</a
    >
    <p class="predictionSerieNoStartlist">
      @if(!serieEMQ){<span>{{ langData.predictionOpened[lang] }}</span> }
      @if(serieEMQ){<span>{{ langData.predictionSaved[lang] }}</span
      >}
    </p>

    <a
      class="btn locked"
      routerLink="/prediction/serie/EM-SF"
      routerLinkActive="active"
      [class]="{ btnPredictionOK: serieEMSF }"
      >{{ langData.semifinal[lang] }}</a
    >
    <p class="predictionSerieNoStartlist">
      {{ langData.waitStartlist[lang] }}
    </p>

    <a
      class="btn locked"
      routerLink="/prediction/serie/EM-F"
      routerLinkActive="active"
      [class]="{ btnPredictionOK: serieEMF }"
      >{{ langData.finalA[lang] }}</a
    >
    <p class="predictionSerieNoStartlist">
      {{ langData.waitStartlist[lang] }}
    </p>
  </div>
</div>
