import { Component } from '@angular/core';
import { FirestoreService } from '../../service/firestore/firestore.service';
import { PlsApiService } from '../../service/pls-api/pls-api.service';
import { Race } from '../../interface/race/race';
import { Athlete } from '../../interface/athlete/athlete';
import { PredictionService } from '../../service/prediction/prediction.service';
import { User } from '../../interface/user/user';

@Component({
  selector: 'app-admin-pls',
  standalone: true,
  imports: [],
  templateUrl: './admin-pls.component.html',
  styleUrl: './admin-pls.component.less',
})
export class AdminPlsComponent {
  playersRegistered?: number;
  seriesSaved?: number;
  serieCurrent: string = '';

  raceList: Race[] = [];
  startlist: Athlete[] = [];
  startlistSerieCurrent: string = '';
  raceListString: string = 'Click on button "Generate"';
  randomGenerated: number = 9001;
  randomGeneratedQty: number = 0;
  randomLoopId: any;

  points: any[] = [];

  constructor(
    private firestoreService: FirestoreService,
    private PLSAPIService: PlsApiService,
    private predService: PredictionService
  ) {}

  ngOnInit() {
    this.getStats();
  }

  //Obtain statistiques of Predictions App
  getStats() {
    console.log('Update Stats');
    const data = this.firestoreService.getStats().subscribe((stat: any) => {
      this.playersRegistered = stat.userRegistered;
      this.seriesSaved = stat.serieSaved;
    });
  }

  //Create JSON to schedule
  generateScheduleJSON() {
    this.raceList = []; //init object

    //Call API PLS
    this.PLSAPIService.getRaceList().subscribe((data) => {
      var myData: any = data;
      myData.result.forEach((race: any) => {
        var predSelect = false; //init if predictions or not
        //Check if predictions of this race
        if (race.raceRef.substr(0, 4) === 'EW-Q') {
          predSelect = true;
        }
        if (race.raceRef.substr(0, 5) === 'EM-SF') {
          predSelect = true;
        }
        if (race.raceRef.substr(0, 5) === 'EM-FA') {
          predSelect = true;
        }
        if (race.raceRef.substr(0, 4) === 'EM-Q') {
          predSelect = true;
        }
        if (race.raceRef.substr(0, 5) === 'EW-SF') {
          predSelect = true;
        }
        if (race.raceRef.substr(0, 5) === 'EW-FA') {
          predSelect = true;
        }

        this.raceList.push({
          code: race.raceRef,
          label: race.raceLabel,
          startTime: race.raceTime,
          predictionSelected: Number(predSelect),
        });
        this.raceListString = JSON.stringify(this.raceList); //create json in string to textarea displayed
      });
    });
  }

  //Get Startlist of serie
  getStartListSerie(serie: string) {
    this.serieCurrent = serie;
    return new Promise((resolve, reject) => {
      this.startlistSerieCurrent = serie; //Save serie to current job
      this.startlist = []; //init

      this.PLSAPIService.getStartList(serie).subscribe((data: any) => {
        const startlist = data.result;

        startlist.forEach((athlete: any) => {
          this.startlist = this.startlist.concat({
            race: athlete.race,
            bib: athlete.bib,
            sex: athlete.sex,
            lastname: athlete.lastname,
            firstname: athlete.firstname,
            nation: athlete.nation,
          });
        });
        resolve(this.startlist);
      });
    });
  }

  /*async getStartListSerie(serie: string) {
    await this.getStartListSeriePromise(serie).then();
  }*/

  pushStartlistSerie() {
    if (this.startlistSerieCurrent !== '') {
      this.firestoreService
        .setStartlist(this.startlistSerieCurrent, this.startlist)
        .subscribe((resPOST: any) => {
          console.log('resPOST', resPOST);

          if (resPOST.result === 'success') {
            //Rest current values
            this.startlist = [];
            this.startlistSerieCurrent = '';
          } else {
            alert('Error push startlist');
          }
        });
    } else {
      alert('no startlist load');
    }
  }

  //Calculpoints
  async calculPointsSerie(serie: string) {
    var newJSON: any = [];
    var predNewJSON: any = [];

    this.serieCurrent = serie;
    this.points = []; //Init values

    //Get Firestore predictions saved
    console.log('Call FirestoreService get 4200 documents :' + new Date());
    const usersData: any = await this.firestoreService.getPredictionsSeries(
      serie
    );
    console.log('Received documents in Angular App :' + new Date());
    console.log('UserDataJSON:');
    console.log(usersData);

    /*usersData.forEach((user: any) => {
      predNewJSON = []; //init value

      user.predictions.forEach((pred: any) => {
        if (pred.isSelected) {
          //console.log('=>', pred.race, pred.isSelected, pred.bib);
          predNewJSON.push({
            race: pred.race,
            bib: pred.bib,
            isSelected: pred.isSelected,
          });
        }
      });

      newJSON.push({
        userId: user.userId,
        serie: user.serie,
        predictions: user.predictions,
      });
    });*/

    //console.log('newFile');
    //console.log(newJSON);

    //Push on PHP calcul points
    const d = await this.PLSAPIService.calculPredictionPointSerie(
      serie,
      usersData /*newJSON*/
    ).subscribe((resPOST) => {
      this.points = resPOST.result;

      //Sort: 1 > 0
      this.points.sort((firstObject: any, secondObject: any) =>
        firstObject.points > secondObject.points ? -1 : 1
      );

      console.log(
        'Received "points" by PHP-PLS server ans sort JSON :' + new Date()
      );
    });
  }

  //
  pushPoints() {
    console.log('SAVE IN DB : ' + this.serieCurrent);
  }

  //Loop of generation
  generateRandomPredictionsLoop(qtyMax: number, delay: number = 1500) {
    console.log('Start Random loop');

    this.randomLoopId = setInterval(() => {
      this.randomGeneratedQty++;
      if (this.randomGeneratedQty > qtyMax) {
        this.generateRandomPredictionsLoopEnd();
      } else {
        this.generateRandomPredictions('EW-Q');
      }
    }, delay);
  }
  //stop
  generateRandomPredictionsLoopEnd() {
    clearInterval(this.randomLoopId);
    console.log('Stop Random loop');
  }

  //generate new user and prediction of serie
  async generateRandomPredictions(serieSelected: string = 'EW-Q') {
    //@Params
    var serie: string = serieSelected;
    this.randomGenerated++;

    //Init
    var raceList: any[] = []; //stock races of serie
    var raceStartlist: any = []; //stock bibs of race; by race
    var randomPrediction: any = []; //stock random predictions

    //code
    //get startlist of serie + list "race list" & "bibs of race"
    await this.getStartListSerie(serie).then();
    this.startlist.forEach((athlete) => {
      let raceIndex: number = raceList.findIndex(
        (element: any) => element === athlete.race
      );
      if (raceList.findIndex((element: any) => element === athlete.race) < 0) {
        raceIndex = raceList.length;
        raceList[raceIndex] = athlete.race;
        raceStartlist[raceIndex] = [];
      }
      raceStartlist[raceIndex].push(athlete.bib);
    });

    raceList.forEach((race: string, index: number) => {
      randomPrediction[index] = []; //init array
      //Random 1st
      const random1st = this.getRandomInt(0, raceStartlist[index].length - 1);
      randomPrediction[index][1] = raceStartlist[index][random1st];
      raceStartlist[index].splice(random1st, 1);

      this.startlist[
        this.startlist.findIndex(
          (el) => el.bib === raceStartlist[index][random1st]
        )
      ].isSelected = 1; //add prediction

      //Random 2nd
      const random2nd = this.getRandomInt(0, raceStartlist[index].length - 1);
      randomPrediction[index][2] = raceStartlist[index][random2nd];
      raceStartlist[index].splice(random2nd, 1);

      this.startlist[
        this.startlist.findIndex(
          (el) => el.bib === raceStartlist[index][random2nd]
        )
      ].isSelected = 2; //add prediction

      //Random 3rd
      const random3rd = this.getRandomInt(0, raceStartlist[index].length - 1);
      randomPrediction[index][3] = raceStartlist[index][random3rd];
      raceStartlist[index].splice(random3rd, 1);

      this.startlist[
        this.startlist.findIndex(
          (el) => el.bib === raceStartlist[index][random3rd]
        )
      ].isSelected = 3; //add prediction
    });

    //Generate new User data
    const newUser: User = {
      lastname: 'MON NOM LONG COMPLEXE ' + this.randomGenerated,
      firstname: 'Firstname xxxx - ' + this.randomGenerated,
      birthDate:
        this.getRandomInt(10, 28) +
        '/0' +
        this.getRandomInt(1, 9) +
        '/' +
        this.getRandomInt(1901, 2024),
      email: 'prolivesport-' + this.randomGenerated + '@prolivesport.fr',
      mobile:
        '0' +
        this.getRandomInt(6, 7) +
        this.getRandomInt(10, 99) +
        this.getRandomInt(10, 99) +
        this.getRandomInt(10, 99) +
        this.getRandomInt(10, 99),
      rules: true,
      id: '',
    };

    var userId: string = '';
    const d = this.firestoreService
      .addUser(newUser)
      .subscribe((resPOST: any) => {
        userId = resPOST.docId;
        console.log('userId', userId);
      });

    //const userId = await this.firestoreService.addUser(newUser);
    //console.log('userId', userId);

    const predData = {
      predictions: this.startlist,
      serie: serie,
      userId: userId,
    };

    //this.firestoreService.addDocument('prediction', predData);
    this.firestoreService.addPrediction(userId, serie, this.startlist);

    console.log('Add new user + 1 serie predictions', newUser.lastname);
  }

  //Generate random in between 2 values
  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  //Check date/hour
  async checkDateHour() {
    console.log('Start Push');
    await this.firestoreService.setUserPoints('ID002', { 'EW-Q': '555' });
    console.log('End Push point');

    const dateDay: Date = new Date();
    const dateCheck: Date = new Date('2024-02-15 18:35:15');

    console.log('date:', dateDay);
    console.log('check:', dateCheck);
    console.log('date T:', dateDay.getTime());
    console.log('check T:', dateCheck.getTime());

    if (dateDay.getTime() > dateCheck.getTime()) {
      console.log('READ');
    } else {
      console.log('WAIT');
    }
  }
}
