<div class="contentMain">
  <h1>Manage</h1>

  <hr />

  <div>
    <button class="btn" (click)="getStats()">Update Stats</button>
    <div id="stats">
      <h2>Stats:</h2>
      <p>Players registered: {{ playersRegistered }}</p>
      <p>Series saved: {{ seriesSaved }}</p>
    </div>

    <hr />

    <div id="push_startlist">
      <h2>Push Startlist:</h2>
      <p>
        <button class="btn" (click)="getStartListSerie('EW-Q')">EW-Q</button>
        <button class="btn" (click)="getStartListSerie('EW-SF')">EW-SF</button>
        <button class="btn" (click)="getStartListSerie('EW-FA')">EW-FA</button>
      </p>
      <p>
        <button class="btn" (click)="getStartListSerie('EM-Q')">EM-Q</button>
        <button class="btn" (click)="getStartListSerie('EM-SF')">EM-SF</button>
        <button class="btn" (click)="getStartListSerie('EM-FA')">EM-FA</button>
      </p>

      <div class="athleteList">
        @for(athlete of startlist;track $index){
        <div class="athleteLine">
          <span class="left">#{{ athlete.bib }}</span>
          <img class="flag" src="assets/flag/{{ athlete.nation }}.png" />
          {{ athlete.firstname }} {{ athlete.lastname }}
          <span class="right">{{ athlete.race }}</span>
        </div>
        } @empty{
        <div class="athleteLine">Any result</div>
        } @if(startlist.length>0){
        <div class="athleteLine">Tot lines: {{ startlist.length }}</div>
        <p>
          <button class="btn" (click)="pushStartlistSerie()">
            PUSH IN APP
          </button>
        </p>
        }
      </div>
    </div>

    <hr />

    <div id="calculPoints">
      <h2>Calcul points:</h2>
      <button class="btn" (click)="calculPointsSerie('EW-Q')">EW-Q</button>
      <button class="btn" (click)="calculPointsSerie('EW-SF')">EW-SF</button>
      <button class="btn" (click)="calculPointsSerie('EW-FA')">EW-FA</button>
      <br />
      <button class="btn" (click)="calculPointsSerie('EM-Q')">EM-Q</button>
      <button class="btn" (click)="calculPointsSerie('EM-SF')">EM-SF</button>
      <button class="btn" (click)="calculPointsSerie('EM-FA')">EM-Q</button>

      <div class="athleteList">
        @for(pts of points;track $index){ @if($index<25){
        <div class="athleteLine">
          <span class="left">{{ pts.points }}pts</span>
          {{ pts.userId }}
          <span class="right">{{ pts.serie }}</span>
        </div>
        } } @empty{
        <div class="athleteLine">Any result</div>
        } @if(points.length>0){
        <div class="athleteLine">Tot lines: {{ points.length }}</div>
        <p>
          <button class="btn" (click)="pushPoints()">
            SAVE {{ serieCurrent }} -> DB
          </button>
        </p>
        }
      </div>
    </div>

    <hr />

    <div id="push_startlist">
      <h2>Schedule</h2>
      <p>
        <button class="btn" (click)="generateScheduleJSON()">
          GENERATE JSON
        </button>
      </p>
      <textarea>
        {{ raceListString }}
      </textarea>
    </div>

    <hr />

    <div id="generate">
      <h2>Random creation:</h2>
      <button class="btn" (click)="generateRandomPredictionsLoop(1, 1000)">
        GENERATE
      </button>
      <button class="btn" (click)="generateRandomPredictionsLoopEnd()">
        STOP
      </button>
    </div>
    <div><button class="btn" (click)="checkDateHour()">DATE</button></div>

    <hr />
  </div>
</div>
