<div class="contentMain contentList">
  <h1>Schedule: {{ langData.menuSchedule[lang] }}</h1>
  @for (race of raceList; track race.code) {
  <div>
    <p class="bold">
      {{ race.startTime }}
      @if(race.predictionSelected > 0){
      <span (click)="goToPrediction()">{{
        langData.schedulePagePrediction[lang]
      }}</span>
      }
    </p>
    <p>{{ race.label }}</p>
  </div>
  }@empty {
  <p class="red error">...</p>
  }
</div>
