import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlsApiService {
  private apiUrl: string;
  private apiUrlEventId: string;
  private eventId: string;

  constructor(private http: HttpClient) {
    this.apiUrl = 'https://www.prolivesport.fr/Graphics_TV_API/';
    this.eventId = '929';
    this.apiUrlEventId = this.apiUrl + this.eventId + '/';
  }

  getRaceList(): Observable<Object[]> {
    const urlGet = this.apiUrlEventId + 'XXX/racelist/';
    return this.http.get<Object[]>(urlGet);
  }

  getStartList(serie: string) {
    const urlGet = this.apiUrlEventId + 'All/startlist/' + serie + '/';
    return this.http.get<Object[]>(urlGet);
  }

  getResult(serie: string) {
    const urlGet = this.apiUrlEventId + serie + '/resultIndiv/';
    return this.http.get<Object[]>(urlGet);
  }

  getAwardSerie(serie: string) {
    const urlGet = this.apiUrlEventId + serie + '/predictionPoints/';
    return this.http.get<Object[]>(urlGet);
  }

  calculPredictionPointSerie(serie: string, data: any): Observable<any> {
    const urlCall = this.apiUrlEventId + serie + '/predictionPoints/';

    return this.http.post<any>(urlCall, data);
  }
}
