<div id="main">
  <div id="header">
    <a routerLink="/" routerLinkActive="active"
      ><img src="assets/img/BannerTop.png"
    /></a>
    <div id="lang">
      <img src="assets/flag/FRA.png" (click)="setLang('fr')" />
      <img src="assets/flag/GBR.png" (click)="setLang('en')" />
    </div>
  </div>
  <div id="menu">
    <a
      class="btn"
      routerLink="/schedule"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
      >📅{{ langData.menuSchedule[lang] }}</a
    >
    <a class="btn" routerLink="/prediction" routerLinkActive="active"
      >🏅{{ langData.menuPrediction[lang] }}</a
    >
    <a class="btn" href="https://ltif-result.fr/program" target="_blank"
      >🏁{{ langData.menuResult[lang] }}</a
    >
    <!--<button>🏁Results</button>-->
  </div>

  <app-logo></app-logo>

  <router-outlet></router-outlet>

  <div id="footer">
    <a href="https://lievintriathlonindoorfestival.com" target="blank"
      >🔗 Lievin Triathlon Indoor Festival</a
    >
  </div>
</div>
