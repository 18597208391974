import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { NavigatorService } from '../../service/navigator/navigator.service';
import { Router } from '@angular/router';
import langJson from '../../../assets/json/lang.json';
import { User } from '../../interface/user/user';
import { FirestoreService } from '../../service/firestore/firestore.service';
import { LoaderComponent } from '../../interface/loader/loader.component';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.less',
  imports: [ReactiveFormsModule, LoaderComponent],
})
//export class CheckBoxValidator {}
export class LoginComponent {
  lang: string = '';
  langData: any = {};

  accessForm = this.formBuilder.group({
    lastname: ['', Validators.required],
    firstname: ['', Validators.required],
    birthDate_day: ['', Validators.required],
    birthDate_month: ['', Validators.required],
    birthDate_year: ['', Validators.required],
    email: ['', [Validators.required, this.mailValidator]],
    rules: [false, Validators.requiredTrue],
  });

  isFirstTry: boolean = true; //l'utilisateur vient d'arriver sur la page de login ?

  focusedInput: HTMLInputElement | null = null;

  validValues: boolean = false;

  isLoading: boolean = false;
  isError: boolean = false;

  constructor(
    private navigator: NavigatorService,
    private router: Router,
    private formBuilder: FormBuilder,
    private firestoreServ: FirestoreService
  ) {
    this.langData = langJson;
    this.lang = this.navigator.getLang();
  }

  ngOnInit(): void {
    if (this.navigator.checkLogOK()) {
      this.router.navigate(['/prediction']);
    }

    document.addEventListener(
      'focus',
      function (event) {
        let focusedElement = event.target;
      },
      true
    );
  }

  //Form accessForm Submit function
  accessFormSubmit() {
    this.isFirstTry = false;

    //check if all Validators are OK
    if (this.accessForm.valid && this.dateValidator(
      this.accessForm.get('birthDate_day')?.value,
      this.accessForm.get('birthDate_month')?.value,
      this.accessForm.get('birthDate_year')?.value
      )) {
      const userLocal: User = {
        lastname: this.accessForm.get('lastname')?.value?.toUpperCase(),
        firstname: this.accessForm.get('firstname')?.value,
        birthDate: /*this.accessForm.get('date')?.value*/ this.birthDateConcat(
          this.accessForm.get('birthDate_day')?.value,
          this.accessForm.get('birthDate_month')?.value,
          this.accessForm.get('birthDate_year')?.value
        ),
        email: this.accessForm.get('email')?.value,
        id: '',
        rules: true,
      };

      this.isLoading = true;
      this.createUserInDatabase(userLocal);

      /********* OLD CODE ***********/
      /*this.navigator.setUser(userLocal);
      this.navigator.getUser();

      console.log('User connected, redirection');
      this.router.navigate(['/prediction']);*/
    } else {
      console.log('invalid forms');
    }
  }

  async createUserInDatabase(data: User) {
    this.firestoreServ.addUser(data).subscribe((resPOST: any) => {
      console.log(resPOST);
      console.log(resPOST.docId);

      data.id = resPOST.docId; //get Id of firestore document

      //Write in localStorage and update display
      console.log('data with Id', data);

      this.navigator.setUser(data);
      this.navigator.getUser();

      console.log(
        'User connected, (redirection Access OK), with ID: ' + data.id
      );
      this.isLoading = false;
      this.router.navigate(['/prediction']);
    }, 
    (error: any) => {
      console.error('Erreur ajout utilisateur: ', error);
      this.isLoading = false;
      this.isError = true;
    });

    //const id = await this.firestoreServ.addUser(data);
  }

  //Concatenate date in french format
  birthDateConcat(day: number | any, month: number | any, year: number | any) {
    let birthDateString = '';

    if (day < 10) {
      birthDateString = '0' + day + '/';
    } else {
      birthDateString = day + '/';
    }

    if (month < 10) {
      birthDateString = birthDateString + '0' + month + '/';
    } else {
      birthDateString = birthDateString + month + '/';
    }

    if (year < 10) {
      birthDateString = birthDateString + '200' + year;
    } else if (year < 24) {
      birthDateString = birthDateString + '20' + year;
    } else if (year < 100) {
      birthDateString = birthDateString + '19' + year;
    } else {
      birthDateString = birthDateString + year;
    }

    return birthDateString;
  }

  //fonction passant le focus sur l'input
  moveFocusToMonth = () => {
    let dayInput = <HTMLInputElement>document.getElementById('day');
    let monthInput = <HTMLInputElement>document.getElementById('month');
    if (dayInput && dayInput?.value.length >= 2) {
      monthInput?.focus();
    }
  };

  //fonction passant le focus sur l'input
  moveFocusToYear = () => {
    let monthInput = <HTMLInputElement>document.getElementById('month');
    let yearInput = <HTMLInputElement>document.getElementById('year');
    if (monthInput && monthInput?.value.length >= 2) {
      yearInput?.focus();
    }
  };

  //fonction quittant forçant le focus à quitter l'input year quand il est remplit de 4 caractères
  blurYear = () => {
    let yearInput = <HTMLInputElement>document.getElementById('year');
    if (yearInput && yearInput?.value.length >= 4) {
      yearInput?.blur();
    }
  };

  mailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const mailValue = control.value;
    let isValidMail = true;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(mailValue)) {
      console.log('Mail valide !');
    } else {
      isValidMail = false;
    }

    if (!isValidMail) {
      return { invalidMail: true };
    }

    return null;
  }

  //retourne true si la date est correcte
  dateValidator(day: any, month: any, year: any) {
    let date = { day: day, month: month, year: year };
    //on vérifie la longueur des données
    //console.log(date.year?.toString().length);
    //console.log('Validateur de date');
    if (
      !(date.day?.length === 2,
      date.month?.length === 2,
      date.year?.toString().length === 4)
    ) {
      return false;
    }
    //jour entre 1 et 31 ? mois entre 1 et 12 ?
    if (
      !(
        parseInt(date.day) <= 31 &&
        parseInt(date.day) >= 1 &&
        parseInt(date.month) <= 12 &&
        parseInt(date.month) >= 1
      )
    ) {
      return false;
    }
    //année entre 1900 et l'année actuelle
    if (
      !(
        parseInt(date.year) >= 1900 &&
        parseInt(date.year) <= new Date().getFullYear()
      )
    ) {
      return false;
    }
    //mois de 31 jours ?
    if (this.monthNumber(parseInt(date.month))) {
      if (parseInt(date.day) > 31) {
        return false;
      }
    } else {
      if (parseInt(date.day) > 30) {
        return false;
      }
    }
    //année bissextile ?
    if (this.checkBissextile(parseInt(date.year))) {
      //bisextille et supérieur au 29 février
      if (parseInt(date.month) === 2 && parseInt(date.day) > 29) {
        return false;
      }
    } else {
      //non bisextille et supérieur au 28 février
      if (parseInt(date.month) === 2 && parseInt(date.day) > 28) {
        return false;
      }
    }
    //console.log('Date validé');
    return true;
  }

  //retourne true si l'année passée en paramètre est bissextile
  checkBissextile = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  //retourne true si le mois possède 31 jours
  monthNumber(month: number) {
    const mois31Jours = [1, 3, 5, 7, 8, 10, 12];
    return mois31Jours.includes(month);
  }

  //fonction formattant l'année au format AAAA
  formatYear = (yearInput: HTMLInputElement) => {
    console.log(yearInput.value.length);
    if(!(yearInput.value.length === 4)){
      if(parseInt(yearInput.value) < 24){
        this.accessForm.get('birthDate_year')?.setValue(`20${yearInput.value}`);
      }
      else{
        this.accessForm.get('birthDate_year')?.setValue(`19${yearInput.value}`);
      }
    }
  }
}
