<div class="contentMain">
  <div class="dd"></div>
  <img
    class="height width"
    [class]="{
      width: logoList[id].size === 'width',
      height: logoList[id].size === 'height'
    }"
    src="assets/logo/{{ logoList[id].image }}"
  />
</div>
