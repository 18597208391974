import { Routes } from '@angular/router';
import { HomeComponent } from './page/home/home.component';
import { ScheduleComponent } from './page/schedule/schedule.component';
import { LoginComponent } from './page/login/login.component';
import { PredictionComponent } from './page/prediction/prediction.component';
import { ListSerieComponent } from './page/list-serie/list-serie.component';
import { AuthComponent } from './PLS/auth/auth.component';

export const routes: Routes = [
  { path: 'schedule', component: ScheduleComponent },
  { path: 'login', component: LoginComponent },
  { path: 'prediction', component: PredictionComponent },
  { path: 'prediction/serie/:serie', component: ListSerieComponent },
  { path: 'pls/access', component: AuthComponent },
];
