import { Component } from '@angular/core';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { NavigatorService } from './service/navigator/navigator.service';
import langJson from '../assets/json/lang.json';
import { LogoComponent } from './page/logo/logo.component';
import { NgClass } from '@angular/common';
import { FirestoreService } from './service/firestore/firestore.service';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
  imports: [RouterOutlet, LogoComponent, NgClass, RouterLink, RouterLinkActive],
})
export class AppComponent {
  title = 'ltif-predictions';

  lang: string = 'en';
  langData: any = [];

  constructor(
    private navigator: NavigatorService,
    private router: Router,
    private firestoreSerivce: FirestoreService
  ) {
    console.log('App');
  }

  ngOnInit() {
    this.langData = langJson;
    console.log('ng on init App');
    
    this.navigator.initService();
    this.lang = this.navigator.getLang();
    //console.log(this.langData);

    //this.appIsStarted = true;

    //Test Firestore
    //this.firestoreSerivce.test();
  }

  setLang(lang: string) {
    this.navigator.changeLang(lang);
    this.lang = lang;
    this.router.navigate(['/']);
  }
}
