<!--<app-logo></app-logo>-->

<div class="navBar">
  <a routerLink="/prediction/" routerLinkActive="active"
    ><img class="left" src="assets/icon/back_green.png" />
  </a>
  <!--<img
    class="right"
    src="../../../assets/icon/bin.png"
    (click)="resetChoice(raceCurrent)"
  />-->
</div>

<div class="contentMain">
  <h1>{{ langData.predictionOf[lang] }}</h1>
  <h2>"{{ serieLabel }}"</h2>
  <div class="separate"></div>
  @if(athleteSelected > 0){<img
    class="absolute right icon"
    src="assets/icon/bin_white.png"
    (click)="resetChoice(raceCurrent)"
  />} @if(!serieCheck){
  <div>
    {{ langData.race[lang] }} {{ pageCurrent }} {{ langData.of[lang] }}
    {{ raceList.length }} -> {{ raceCurrent }}
  </div>
  } @if(!serieCheck){
  <div class="description">
    {{ descriptionLabel }}
  </div>
  } @if(serieCheck){
  <div>{{ langData.summaryChoice[lang] }}:</div>
  }

  <!-- SUMMARY -->
  @if(serieCheck){
  <div class="athleteList">
    @for(race of raceList; track $index){
    <div class="raceTitle">{{ race }}</div>
    @for(athlete of athleteSummary; track $index){
    <div>
      @if(athlete.athlete.race === race){
      <div>
        <div
          class="athleteLine"
          [class]="{
            gold: athlete.medal === 'gold',
            silver: athlete.medal === 'silver',
            bronze: athlete.medal === 'bronze'
          }"
        >
          <span class="left">#{{ athlete.athlete.bib }}</span>
          <img
            class="flag"
            src="assets/flag/{{ athlete.athlete.nation }}.png"
          />
          {{ athlete.athlete.firstname }}
          {{ athlete.athlete.lastname }} @if(athlete.athlete.isSelected ===
          1){<span class="right">🥇</span>} @if(athlete.athlete.isSelected ===
          2){<span class="right">🥈</span>} @if(athlete.athlete.isSelected ===
          3){<span class="right">🥉</span>}
        </div>
      </div>
      }
    </div>
    }@empty{} }@empty { }
  </div>
  }

  <div>
    @if(athleteSelected >= 3 && !isLoading){
    <a class="btn" (click)="nextPage()">
      @if(pageCurrent < raceList.length){
      <span>{{ langData.nextRace[lang] }}</span>
      } @if(pageCurrent === raceList.length){
      <span>{{ langData.save[lang] }}</span>
      }
    </a>
    } @if(serieCheck){
    <a
      class="btn btnBottomBack"
      routerLink="/prediction/"
      routerLinkActive="active"
      >{{ langData.back[lang] }}</a
    >
    }
  </div>

  <!-- CHOICE -->
  @if(!serieCheck){
  <div class="athleteList">
    @for(athlete of athleteList; track athlete.bib){
    <div
      class="athleteLine"
      (click)="clickAthlete(athlete.bib)"
      [class]="{
        gold: athlete.isSelected === 1,
        silver: athlete.isSelected === 2,
        bronze: athlete.isSelected === 3
      }"
    >
      <span class="left">#{{ athlete.bib }}</span>
      <img class="flag" src="assets/flag/{{ athlete.nation }}.png" />
      {{ athlete.firstname }} {{ athlete.lastname }} @if(athlete.isSelected ===
      1){<span class="right" id="{{ athlete.bib }}">🥇</span>}
      @if(athlete.isSelected === 2){<span class="right" id="{{ athlete.bib }}"
        >🥈</span
      >} @if(athlete.isSelected === 3){<span
        class="right"
        id="{{ athlete.bib }}"
        >🥉</span
      >}
    </div>
    }@empty {}
  </div>
  }

  <div class="btnBottom">
    @if(athleteSelected >= 3){<a class="btn" (click)="nextPage()">
      @if(pageCurrent < raceList.length){<span>{{
        langData.nextRace[lang]
      }}</span
      >} @if(pageCurrent === raceList.length){<span>{{
        langData.save[lang]
      }}</span
      >} </a
    >}
  </div>
</div>
@if(isLoading){<app-loader></app-loader>}
