import { Component } from '@angular/core';

@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.less',
})
export class LogoComponent {
  string: string = 'width';
  id: number = 0;
  loopId: any;
  logoList: any[] = [];

  //Constructor
  constructor() {
    //Init list of logo
    this.logoList.push({ image: 'Logo_tempo.png', size: 'width' });
    this.logoList.push({ image: 'Logo_PLS.png', size: 'height' });
    this.logoList.push({ image: 'Logo_WT_blue.png', size: 'width' });
    this.logoList.push({ image: 'Logo_TCL.png', size: 'width' });
    this.logoList.push({ image: 'Logo_EDF_Sport.png', size: 'width' });
    this.logoList.push({ image: 'Logo_Prolinair.png', size: 'width' });
    //this.logoList.push({ image: 'Logo_Eaux_Artois.png', size: 'height' });
  }

  //Function => init
  ngOnInit(): void {
    this.loopId = setInterval(() => {
      this.nextLogo();
    }, 2000);
  }

  //Lifecycle => exit
  ngOnDestroy(): void {
    clearInterval(this.loopId);
  }

  //Function => display next logo
  nextLogo() {
    //Check if in arrayList
    if (this.id + 1 < this.logoList.length) {
      this.id++; //go to next value of array
    } else {
      this.id = 0; //go to begin of array
    }
  }
}
