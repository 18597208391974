@if(!auth){
<div class="contentMain">
  <h1>PLS Auth</h1>
  <form [formGroup]="authForm" (ngSubmit)="authFormSubmit()">
    <p>
      <label>Code:</label>
      <input
        formControlName="code"
        placeholder="Code"
        type="password"
        class="formBirthDate"
      />
      <input
        formControlName="key"
        placeholder="key"
        type="password"
        class="formBirthDate"
      />
    </p>
    @if(errorMessage!==''){
    <p class="error red">{{ errorMessage }}</p>
    }

    <p>
      <input
        type="submit"
        value="AUTH"
        class="formBirthDate"
        [disabled]="!authForm.valid"
      />
    </p>
  </form>
</div>
} @else{
<app-admin-pls></app-admin-pls>
}
