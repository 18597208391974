import { Injectable } from '@angular/core';
import { Athlete } from '../../interface/athlete/athlete';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root',
})
export class PredictionService {
  raceList: string[] = []; //races of Serie selected
  athleteList: Athlete[] = []; //global athlete list

  constructor(private firestoreService: FirestoreService) {
    this.init();
  }

  //Init values & service
  init() {
    //DEV ***************   init athletes value in localstorage
    if (Object.keys(this.storageGet('EW-Q')).length === 0) {
      this.firestoreService.getStartlist('EW-Q').subscribe((startlist: any) => {
        console.log('result Google functions', startlist);

        this.storagePush('EW-Q', startlist.data.startlist);
      });
    }
    if (Object.keys(this.storageGet('EM-Q')).length === 0) {
      this.firestoreService.getStartlist('EM-Q').subscribe((startlist: any) => {
        console.log('result Google functions', startlist);

        this.storagePush('EM-Q', startlist.data.startlist);
      });
    }
    //*************************************************** */

    this.raceList = [];
    this.athleteList = [];
  }

  calculAthlete(serie: any) {
    this.init(); //init

    this.athleteList = this.storageGet(serie); //get all athletes of serie selected

    //Calcul unique race
    if (this.athleteList.length > 0) {
      this.athleteList.forEach((athlete) => {
        if (Object.values(this.raceList).indexOf(athlete.race) === -1) {
          this.raceList.push(athlete.race);
        }
      });
    }
  }

  getAthleteList(race: string) {
    const raceAthleteList: Athlete[] = []; //initialized array

    this.athleteList.forEach((athlete) => {
      if (athlete.race === race) {
        raceAthleteList.push(athlete);
      }
    });

    return raceAthleteList;
  }

  getRaceList(serie: any) {
    this.calculAthlete(serie);

    return this.raceList;
  }

  //Save in localStorage predictions of race X
  savePredictionRace(race: string, athleteList: Athlete[]) {
    this.storagePush('pred_' + race, athleteList);
  }
  getPredictionRace(race: string) {
    return this.storageGet('pred_' + race);
  }
  savePredictionSerie(serie: string | null) {
    this.storagePush('save_' + serie, { value: true });
  }
  getPredictionSerie(serie: string | null) {
    return this.storageGet('save_' + serie);
  }

  //Push in localStorage
  private storagePush(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }
  //Read localStorage
  private storageGet(key: string) {
    return JSON.parse(localStorage.getItem(key) || '{}');
  }
}
