{
  "menuSchedule": { "en": "Schedule", "fr": "Programme" },
  "menuPrediction": { "en": "Predictions", "fr": "Pronostics" },
  "menuResult": { "en": "Results", "fr": "Live" },
  "schedulePagePrediction": { "en": "PREDICTIONS", "fr": "PRONOSTICS" },

  "loginPageTitle": {
    "en": "Predictions Access",
    "fr": "Accès aux pronostics"
  },
  "lastname": { "en": "Lastname", "fr": "Nom" },
  "firstname": { "en": "Firstname", "fr": "Prénom" },
  "day": { "en": "Day", "fr": "Jour" },
  "month": { "en": "Month", "fr": "Mois" },
  "year": { "en": "Year", "fr": "Année" },
  "formAccess_day": { "en": "DD", "fr": "JJ" },
  "formAccess_month": { "en": "MM", "fr": "MM" },
  "formAccess_year": { "en": "YYYY", "fr": "AAAA" },
  "email": { "en": "Email", "fr": "Email" },
  "mobile": { "en": "Mobile number", "fr": "N° mobile" },
  "rules": { "en": "Accept rules", "fr": "Acceptation règlement" },
  "rulesPDF": { "en": "Open PDF", "fr": "Ouvrir PDF" },
  "loginSubmit": { "en": "GO TO PREDICTIONS", "fr": "ACCES PRONOSTICS" },
  "lastnameError": { "en": "Lastname required", "fr": "Nom absent" },
  "firstnameError": { "en": "Firstname required", "fr": "Prénom absent" },
  "birthDateFormat": {
    "en": "dd/mm/yyyy",
    "fr": "jj/mm/aaaa"
  },
  "birthDateError": {
    "en": "BirthDate required or invalid",
    "fr": "Année nais. obligatoire ou invalide"
  },
  "rulesError": {
    "en": "Please accept rules to participate",
    "fr": "Merci d'accepter le règlement pour participer"
  },
  "mobileError": {
    "en": "Mobile number required or wrong format",
    "fr": "N° mobile absent ou au mauvais format"
  },
  "emailError": {
    "en": "Email required or wrong format",
    "fr": "Email absent ou au mauvais format"
  },
  "registerError": {
    "en": "An error occurred during register",
    "fr": "Une erreur est survenue durant l'enregistrement"
  },
  "eliteWomen": {
    "en": "Elite Women",
    "fr": "Elite Femmes"
  },
  "eliteMen": {
    "en": "Elite Men",
    "fr": "Elite Hommes"
  },
  "qualifier": {
    "en": "Qualifiers",
    "fr": "Qualifications"
  },
  "semifinal": {
    "en": "Semifinals",
    "fr": "1/2 finales"
  },
  "finalA": {
    "en": "Final A",
    "fr": "Finale A"
  },
  "predictionOpened": {
    "en": "Predictions opened",
    "fr": "Pronostics ouverts"
  },
  "predictionClosed": {
    "en": "Predictions closed",
    "fr": "Pronostics fermés"
  },
  "predictionSaved": {
    "en": "Predictions saved",
    "fr": "Pronostics enregistrés"
  },
  "waitStartlist": {
    "en": "Wait startlist to make your predictions",
    "fr": "Attente start listes officielles"
  },

  "predictionOf": {
    "en": "Predictions of",
    "fr": "Pronostics de"
  },
  "race": {
    "en": "Race",
    "fr": "Epreuve"
  },
  "of": {
    "en": "of",
    "fr": "sur"
  },
  "clickOn1st": {
    "en": "Click on an athlete to select 1st",
    "fr": "Cliquez sur un athlète pour choisir le 1er"
  },
  "clickOn2nd": {
    "en": "Click on an athlete to select 2nd",
    "fr": "Cliquez sur un athlète pour choisir le 2e"
  },
  "clickOn3rd": {
    "en": "Click on an athlete to select 3rd",
    "fr": "Cliquez sur un athlète pour choisir le 3e"
  },
  "clickOnStop": {
    "en": "Thanks to your selection => next race",
    "fr": "Merci pour votre sélection => Epreuve suivante"
  },
  "predictionChoiceAlready": {
    "en": "You have already made your predictions for this races serie",
    "fr": "Vous avez déjà fait vos pronostics pour cette série"
  },
  "save": {
    "en": "SAVE",
    "fr": "ENREGISTRER"
  },
  "nextRace": {
    "en": "NEXT RACE",
    "fr": "EPREUVE SUIVANTE"
  },
  "back": {
    "en": "BACK",
    "fr": "RETOUR"
  },
  "summaryChoice": {
    "en": "Summary of your choices",
    "fr": "Résumé de vos choix"
  },
  "gold": {
    "en": "Gold",
    "fr": "Or"
  },
  "silver": {
    "en": "Silver",
    "fr": "Argent"
  },
  "bronze": {
    "en": "Bronze",
    "fr": "Bronze"
  }
}
